import React from "react"
// import YouTube from "react-youtube"
import Iframe from "react-iframe"

export default function YouTubePlaylist({ playlistId }) {
    return (
        <div style={{ textAlign: "center" }}>
            <Iframe
                width="800"
                height="450"
                src={`https://www.youtube.com/embed?listType=playlist&list=${playlistId}`}
                title="YouTube Playlist"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            ></Iframe>
        </div>
    )
}