import React from 'react'
import { Link } from 'react-router-dom'

export default function CreateBusinessProfile() {
    return (
        <section className='w-[350px] px-3 py-7 smsize:w-full'>
            <div className='relative w-full bg-[#F0A3A3] rounded-full h-[63px] '>
                <Link to="/for-business">
                    <div className='absolute top-[-5px] left-[-3px] w-full bg-[#520A0A] rounded-full h-[63px] flex items-center justify-center gap-3'>
                        <h3 className='text-[16px] font-light text-[#FAF5EA]'><strong>Retailers: </strong>Create your Free Profile</h3>
                        {/* <img src="/gift-guide/arrow.png" alt="Gift Guide" /> */}
                    </div>
                </Link>
            </div>
        </section>
    )
}
