import React from 'react'
import YouTube from 'react-youtube'

const YoutubeEmbed = ({
    videoId
}) => {
    // const videoId = '8N3uygRII-4' // YouTube ID

    // config for YouTube player
    const opts = {
        height: '450',
        width: '800',
        playerVars: {
            autoplay: 0, // autoplay
            modestbranding: 1, // hide YouTube Logo
            rel: 0, // don't show related videos
            fs: 0, // hide full screen button
            controls: 1, // show controls bar
        },
    }

    const onEnd = (event) => {
        event.target.stopVideo() // stop video
        event.target.clearVideo() // clear video
    }

    return (
        <div>
            <YouTube videoId={videoId} opts={opts} onEnd={onEnd} />
        </div>
    )
}

export default YoutubeEmbed